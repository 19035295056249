var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "relationProjectCriticalForm",
          staticClass: "relationProjectCritical",
          attrs: {
            model: _vm.relationProjectCriticalForm,
            rules: _vm.relationProjectCriticalFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "等级", prop: "level" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.relationProjectCriticalForm.level,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationProjectCriticalForm, "level", $$v)
                    },
                    expression: "relationProjectCriticalForm.level",
                  },
                },
                _vm._l(_vm.levelPriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.relationProjectCriticalForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationProjectCriticalForm, "type", $$v)
                    },
                    expression: "relationProjectCriticalForm.type",
                  },
                },
                _vm._l(_vm.typePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "区间值", prop: "intervalValue" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入区间值",
                },
                model: {
                  value: _vm.relationProjectCriticalForm.intervalValue,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.relationProjectCriticalForm,
                      "intervalValue",
                      $$v
                    )
                  },
                  expression: "relationProjectCriticalForm.intervalValue",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "适合性别", prop: "allowSex" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.relationProjectCriticalForm.allowSex,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationProjectCriticalForm, "allowSex", $$v)
                    },
                    expression: "relationProjectCriticalForm.allowSex",
                  },
                },
                _vm._l(_vm.allowSexPriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "年龄最低值", prop: "minAge" } },
            [
              _c("InputNumber", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请输入年龄最低值",
                },
                model: {
                  value: _vm.relationProjectCriticalForm.minAge,
                  callback: function ($$v) {
                    _vm.$set(_vm.relationProjectCriticalForm, "minAge", $$v)
                  },
                  expression: "relationProjectCriticalForm.minAge",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "年龄最高值", prop: "maxAge" } },
            [
              _c("InputNumber", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请输入年龄最高值",
                },
                model: {
                  value: _vm.relationProjectCriticalForm.maxAge,
                  callback: function ($$v) {
                    _vm.$set(_vm.relationProjectCriticalForm, "maxAge", $$v)
                  },
                  expression: "relationProjectCriticalForm.maxAge",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }